<template>
  <div>
    <el-dialog
      title="版本"
      :visible.sync="versionDialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <SchemaTable 
      v-if="versionDialogVisible"
      :hasQuery="false"
      :hasAction="true"
      :canCreate=false
      ref="schematablerefVersion" :schema="schemaVersion" :model="dataModelVersion">
        <template #actionBtns="{ row }">
          <el-link v-if="row.activated != 1" type="primary" class="action-link" @click="switchVersion(row)">
            切换
          </el-link>
          <el-link type="primary" class="action-link" @click="goCopy(row)">
            复制
          </el-link>
          <el-link v-if="row.activated != 1" type="primary" class="action-link" @click="deleteVersion(row)">
            删除
          </el-link>
        </template>
      </SchemaTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="步骤排序"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <DraggableList :list="sortData" :selectable="true">
        <template #item="{ item }">
          <DraggableListItem
            :item="item"
            v-if="!item.delete"
            @select="onSelect(item)"
            @delete="onDelete(item)"
          >
            {{ item.name }}
          </DraggableListItem>
        </template>
      </DraggableList>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="sortSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-row :gutter="20" class="page-box task-list-page">
      <el-col>
        <Breadcrumb
          :home="false"
          :breadcrumbs="[
            {
              label: '制程管理',
              to: '/workflow',
            },
            {
              label: '步骤管理',
              to: '',
            },
          ]"
        />
      </el-col>
      <el-col>
        <el-card class="grid-card" shadow="never">
          <el-tabs
            v-model="editableTabsValue"
            type="card"
            @tab-remove="removeTab"
          >
            <el-tab-pane
              v-for="(item, index) in editableTabs"
              :key="item.name"
              :closable="index != 0"
              :label="item.title"
              :name="item.name"
            >
              <span v-if="index == 0">
                <SchemaTable
                  ref="schematableref"
                  :schema="schema"
                  :model="dataModel"
                  :queryConf="queryConf"
                  :filters="filters"
                  :hasAction="hasAction"
                  :hasQuery="hasQuery"
                  :defaultFormData="defaultFormData"
                  :hasPagination="false"
                  :formConf="processFormConf"
                >
                  <template #actionBtns="{ row }">
                    <el-link
                      type="primary"
                      class="action-link"
                      @click="goProcedure(row)"
                    >
                      <!-- <i class="el-icon-download" /> -->
                      工序管理
                    </el-link>
                  </template>

                  <template #queryBtns="{ row }">
                    <el-button
                      type="primary"
                      class="action-link"
                      style="float: right"
                      @click="sort(row)"
                    >
                      步骤排序
                    </el-button>
                    <el-button
                      type="primary"
                      class="action-link"
                      style="float: right"
                      @click="moreVersions(row)"
                    >
                      更多版本
                    </el-button>
                  </template>
                </SchemaTable>
              </span>
              <span v-else>
                <procedure
                  :processId="processId"
                  :workflowId="workflowId"
                ></procedure>
              </span>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import { DraggableList, DraggableListItem } from "enn-draggable-list";

import schema from "./process.schema.json";
import schemaVersion from "./version.schema.json";
import { uris, attachId } from "./../../api/uri";
import Breadcrumb from "enn-breadcrumb";
import Procedure from "./../procedure/index.vue";

import axios from "axios";
import _ from "lodash";
export default {
  components: {
    SchemaTable,
    DraggableList,
    DraggableListItem,
    Breadcrumb,
    Procedure,
  },

  data() {
    return {
      sortData: [],
      versionDialogVisible: false,
      editableTabsValue: "1",
      editableTabs: [
        {
          title: "步骤管理",
          name: "1",
          id: 0,
        },
      ],
      tabIndex: 1,
      onSelect(item) {
        console.log("onSelect", item);
      },
      onDelete(item) {
        this.$confirm("确认删除?", null, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
          .then(() => {
            this.sortData = this.sortData.map((field) => {
              if (field.id === item.id) field.delete = true;
              return field;
            });

            this.$message({
              type: "success",
              message: "已标记删除，待提交删除",
            });
          })
          .catch(() => {});
      },

      hasAction: true,
      dialogVisible: false,
      hasQuery: true,
      defaultFormData: {},
      search: "",
      filters: ["name"],
      schema,
      schemaVersion,
      // hasReset: true,
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: `${uris.process}`,
        getListMap: (item) => {
          this.workflowId = item.workflowId;
          item.createdTime = +new Date(item.created_at);
          this.editableTabs[0].title = item.workflow ? item.workflow.name : "";
          return item;
        },
        getApi: `${uris.process}${attachId}`,
        getMap(res) {
          return res;
        },
        deleteApi: `${uris.process}${attachId}`,
        createApi: uris.process,
        updateApi: `${uris.process}${attachId}`,
        query: {
          pageNumber: 1,
          pageSize: 100,
          groupId: this.$route.query.groupId,
        },
        axiosConfig: {
          timeout: 10000,
        },
      }),
      dataModelVersion: new DataModel({
        getListApi: `${uris.workflowByGroup}`,
        getListMap: (item) => {
          item.createdTime = +new Date(item.created_at);
          if (item.activated == 1) {
            item.version = item.version + '（当前使用版本）' 
          }
          return item;
        },
        query: {
          pageNumber: 1,
          pageSize: 100,
          groupId: this.$route.query.groupId,
        },
      }),
      processFormConf: {
        getOptions: this.getDependProcess.bind(this),
      },
    };
  },
  created() {
    this.defaultFormData.groupId = this.$route.query.groupId;
    this.groupId = this.$route.query.groupId;
    axios
        .get(`${uris.workflowOneByGroup}?groupId=${this.groupId}`, {
          
        })
        .then((res) => {
          if (res.data.code !== 0) {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }
          this.workflowId = res.data.data.id;
        });
  },
  methods: {
    switchVersion(row) {
      axios
        .post(`${uris.workflowActived}`, {
          workflowId: row.id,
          version: Number(row.version.replace('v','').replace('.','')),
          groupId: row.groupId,
        })
        .then((res) => {
          if (res.data.code !== 0) {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }
          this.$message({
            type: "success",
            message: "版本切换成功",
          });
          this.workflowId = row.id;
          if (Array.isArray(this.$refs.schematablerefVersion)) {
            this.$refs.schematablerefVersion[0].onSearch();
          } else {
            this.$refs.schematablerefVersion.onSearch();
          }
        });
    },
    deleteVersion(row) {
      axios
        .delete(`${uris.workflow}/${row.id}`, {
        })
        .then((res) => {
          if (res.data.code !== 0) {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }
          this.$message({
            type: "success",
            message: "版本删除成功",
          });
          if (Array.isArray(this.$refs.schematablerefVersion)) {
            this.$refs.schematablerefVersion[0].onSearch();
          } else {
            this.$refs.schematablerefVersion.onSearch();
          }
        });
    },
    goCopy(row) {
       axios
        .post(`${uris.workflowCopy}`, {
          workflowId: row.id,
        })
        .then((res) => {
          if (res.data.code !== 0) {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }
          this.$message({
            type: "success",
            message: "新版本复制完成",
          });
          if (Array.isArray(this.$refs.schematablerefVersion)) {
            this.$refs.schematablerefVersion[0].onSearch();
          } else {
            this.$refs.schematablerefVersion.onSearch();
          }
        });
    },
    moreVersions(row) {
      this.versionDialogVisible = true;
    },
    goProcedure(row) {
      this.processId = row.id;
      let newTabName = ++this.tabIndex + "";
      for (const tab of this.editableTabs) {
        if (tab.title === row.name) {
          this.editableTabsValue = tab.name;
          return;
        }
      }
      this.editableTabs.push({
        title: row.name,
        name: newTabName,
        id: this.tabIndex,
      });
      this.editableTabsValue = newTabName;
      // this.$router.push({
      //   name: "procedure",
      //   query: { processId: row.id, workflowId: this.workflowId },
      // });
    },
    async sort() {
      this.sortData = (await this.dataModel.getList()).list;
      this.sortData = this.sortData.map((item) => {
        return _.pick(item, ["id", "sortIndex", "name"]);
      });
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.versionDialogVisible = false;
      if (Array.isArray(this.$refs.schematableref)) {
            this.$refs.schematableref[0].onSearch();
          } else {
            this.$refs.schematableref.onSearch();
          }
    },
    async sortSubmit() {
      axios
        .put(`${uris.processSort}`, {
          sortData: this.sortData,
          workflowId: this.workflowId,
        })
        .then((res) => {
          if (res.data.code !== 0) {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }
          this.$message({
            type: "success",
            message: "已更新",
          });
          if (Array.isArray(this.$refs.schematableref)) {
            this.$refs.schematableref[0].onSearch();
          } else {
            this.$refs.schematableref.onSearch();
          }
          this.handleClose();
        });
    },
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
    },
    getDependProcess() {
      return this.dataModel.getList().then((res) => {
        const prcessList = res.list; 
        const processOptions = prcessList.map((item)=>{
            return {value:item.id,label:item.name}
        });
        return processOptions;
      });
    },
  },
};
</script>

<style scoped>
</style>