<template>
  <div :key="processId">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <DraggableList :list="sortData" :selectable="true">
        <template #item="{ item }">
          <DraggableListItem
            :item="item"
            @select="onSelect(item)"
            @delete="onDelete(item)"
          >
            {{ item.name }}
          </DraggableListItem>
        </template>
      </DraggableList>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="sortSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <SchemaTable
      ref="schematableref"
      :schema="schema"
      :model="dataModel"
      :filters="filters"
      :queryConf="queryConf"
      :defaultFormData="defaultFormData"
      :formConf="formConf"
      :hasPagination="false"
      queryLayout="appendBtnSearch"
      :search="search"
    >
      <!-- <template #actionBtns="{ row }">
            <el-link class="action-link" @click="to(row)"> 参数编辑 </el-link>
          </template> -->
      <template #actionBtns="{ row }">
        <el-link type="primary" class="action-link" @click="goProcedure(row)">
          编辑
        </el-link>
      </template>

      <template #queryBtns="{ row }">
        <el-button
          type="primary"
          class="action-link"
          style="float: right"
          @click="sort(row)"
        >
          工序排序
        </el-button>
      </template>
    </SchemaTable>
  </div>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./procedure.schema.json";
import { uris, attachId } from "./../../api/uri";
import { DraggableList, DraggableListItem } from "enn-draggable-list";

import _ from "lodash";
import axios from "axios";

export default {
  components: {
    SchemaTable,
    DraggableList,
    DraggableListItem,
  },
  props: {
    processId: String,
    workflowId: String,
    groupId: String,
  },
  created() {
    this.defaultFormData.processId = this.processId;
  },
  data() {
    return {
      formConf: {
        getServerOptions: this.getServerOptions.bind(this),
      },
      defaultFormData: {},
      queryConf: { closePrefix: true },
      dialogVisible: false,
      search: "请输入工序名称或者工序序号",
      schema,
      sortData: [],
      filters: [],
      onSelect(item) {},
      onDelete(item) {
        this.$confirm("确认删除?", null, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
          .then(() => {
            this.sortData = this.sortData.map((field) => {
              if (field.id === item.id) field.delete = true;
              return field;
            });

            this.$message({
              type: "success",
              message: "已标记删除，待提交删除",
            });
          })
          .catch(() => {});
      },
      dataModel: new DataModel({
        getListApi: `${uris.procedure}`,
        getListMap(item) {
          item.createdTime = +new Date(item.created_at);
          return item;
        },
        getApi: `${uris.procedureJson}${attachId}`,
        getMap(res) {
          res.isParent = res.parentId ? true : false;
          return res;
        },
        deleteApi: `${uris.procedure}${attachId}`,
        createApi: uris.procedureJson,
        // updateApi: `${uris.procedure}${attachId}`,
        query: {
          pageNumber: 1,
          pageSize: 100,
          processId: this.processId,
        },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {
    async getServerOptions(scenario) {
      const optionMd = new DataModel({
        getApi: "/api/v1/procedure?pageSize=100&processId=" + this.processId,
      });
      const res = await optionMd.get();
      let options = await this.optionsFormat(res.list);
      return options;
    },
    async optionsFormat(list, valueKey = "id", labelKey = "name") {
      if (!Array.isArray(list)) {
        return [];
      }
      let str = JSON.stringify(list)
        .replace(new RegExp(`"${valueKey}":`, "g"), '"value":')
        .replace(new RegExp(`"${labelKey}":`, "g"), '"label":');
      return JSON.parse(str);
    },
    goProcedure(row) {
      this.$router.push({
        name: "workflow-procedure-edit",
        query: {
          procedureId: row.id,
          processId: this.processId,
          workflowId: this.workflowId,
          groupId: this.groupId,
        },
      });
    },
    async sort() {
      this.sortData = (await this.dataModel.getList()).list;
      this.sortData = this.sortData.map((item) => {
        return _.pick(item, ["id", "sortIndex", "name"]);
      });
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    async sortSubmit() {
      axios
        .put(`${uris.procedureSort}`, {
          sortData: this.sortData,
          processId: this.processId,
        })
        .then((res) => {
          if (res.data.code !== 0) {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }
          this.$message({
            type: "success",
            message: "已更新",
          });
          this.$refs.schematableref.onSearch();
          this.handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    processId: function () {
      this.$set(this.dataModel, "query", {
        pageNumber: 1,
        pageSize: 100,
        processId: this.processId,
      });
      this.$set(this.defaultFormData, "processId", this.processId);
    },
  },
  computed: {},
};
</script>

<style scoped>
</style>