var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"版本","visible":_vm.versionDialogVisible,"before-close":_vm.handleClose,"close-on-click-modal":false,"close-on-press-escape":false},on:{"update:visible":function($event){_vm.versionDialogVisible=$event}}},[(_vm.versionDialogVisible)?_c('SchemaTable',{ref:"schematablerefVersion",attrs:{"hasQuery":false,"hasAction":true,"canCreate":false,"schema":_vm.schemaVersion,"model":_vm.dataModelVersion},scopedSlots:_vm._u([{key:"actionBtns",fn:function(ref){
var row = ref.row;
return [(row.activated != 1)?_c('el-link',{staticClass:"action-link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.switchVersion(row)}}},[_vm._v(" 切换 ")]):_vm._e(),_c('el-link',{staticClass:"action-link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.goCopy(row)}}},[_vm._v(" 复制 ")]),(row.activated != 1)?_c('el-link',{staticClass:"action-link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.deleteVersion(row)}}},[_vm._v(" 删除 ")]):_vm._e()]}}],null,false,3278360549)}):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("关 闭")])],1)],1),_c('el-dialog',{attrs:{"title":"步骤排序","visible":_vm.dialogVisible,"before-close":_vm.handleClose,"close-on-click-modal":false,"close-on-press-escape":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('DraggableList',{attrs:{"list":_vm.sortData,"selectable":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(!item.delete)?_c('DraggableListItem',{attrs:{"item":item},on:{"select":function($event){return _vm.onSelect(item)},"delete":function($event){return _vm.onDelete(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}}])}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.sortSubmit}},[_vm._v("确 定")])],1)],1),_c('el-row',{staticClass:"page-box task-list-page",attrs:{"gutter":20}},[_c('el-col',[_c('Breadcrumb',{attrs:{"home":false,"breadcrumbs":[
          {
            label: '制程管理',
            to: '/workflow',
          },
          {
            label: '步骤管理',
            to: '',
          } ]}})],1),_c('el-col',[_c('el-card',{staticClass:"grid-card",attrs:{"shadow":"never"}},[_c('el-tabs',{attrs:{"type":"card"},on:{"tab-remove":_vm.removeTab},model:{value:(_vm.editableTabsValue),callback:function ($$v) {_vm.editableTabsValue=$$v},expression:"editableTabsValue"}},_vm._l((_vm.editableTabs),function(item,index){return _c('el-tab-pane',{key:item.name,attrs:{"closable":index != 0,"label":item.title,"name":item.name}},[(index == 0)?_c('span',[_c('SchemaTable',{ref:"schematableref",refInFor:true,attrs:{"schema":_vm.schema,"model":_vm.dataModel,"queryConf":_vm.queryConf,"filters":_vm.filters,"hasAction":_vm.hasAction,"hasQuery":_vm.hasQuery,"defaultFormData":_vm.defaultFormData,"hasPagination":false,"formConf":_vm.processFormConf},scopedSlots:_vm._u([{key:"actionBtns",fn:function(ref){
        var row = ref.row;
return [_c('el-link',{staticClass:"action-link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.goProcedure(row)}}},[_vm._v(" 工序管理 ")])]}},{key:"queryBtns",fn:function(ref){
        var row = ref.row;
return [_c('el-button',{staticClass:"action-link",staticStyle:{"float":"right"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.sort(row)}}},[_vm._v(" 步骤排序 ")]),_c('el-button',{staticClass:"action-link",staticStyle:{"float":"right"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.moreVersions(row)}}},[_vm._v(" 更多版本 ")])]}}],null,true)})],1):_c('span',[_c('procedure',{attrs:{"processId":_vm.processId,"workflowId":_vm.workflowId}})],1)])}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }