const basePath = `/api/v1`;

const mergeUri = (path) => `${basePath}${path}`;
const uris = {
  user: mergeUri(`/user`),
  role: mergeUri(`/role`),
  product: mergeUri(`/product`),
  order: mergeUri(`/order`),
  orderExport: mergeUri(`/order/exportInfo`),
  factory: mergeUri(`/factory`),
  factoryExport: mergeUri(`/factory/export`),
  exportProcess: mergeUri(`/factory/exportProcess`),
  process: mergeUri(`/process`),
  processSort: mergeUri(`/process/sort`),
  processByActiveWorkflow:mergeUri('/process/activeWorkflow'),
  workflowCopy: mergeUri(`/workflow/copy`),
  workflow: mergeUri(`/workflow`),
  workflowExport: mergeUri(`/workflow/export`),
  workflowByGroup: mergeUri(`/workflow/byGroup`),
  workflowActived: mergeUri(`/workflow/actived`),
  procedure: mergeUri(`/procedure`),
  procedureSort: mergeUri(`/procedure/sort`),
  procedureJson: mergeUri(`/procedure/json`),
  log: mergeUri(`/log`),
  procedureLog: mergeUri(`/procedureLog`),
  sn: mergeUri(`/sn`),
  mac: mergeUri(`/mac`),
  gweui: mergeUri(`/gweui`),
  productLine: mergeUri(`/productLine`),
  workPlace: mergeUri(`/workPlace`),
  processWorkPlace: mergeUri(`/processWorkPlace`),
  workflowOneByGroup: mergeUri(`/workflow/oneByGroup`),
  device: mergeUri(`/device`),
  workflowLife: mergeUri(`/workflowLife`),
  processLog: mergeUri(`/processLog`),
  opLog: mergeUri(`/opLog`),
  macRequisition: mergeUri(`/macRequisition`),
  syncOrder: mergeUri(`/order/syncOrder`),
};
const attachId = `/:id`;
export { uris, attachId };
